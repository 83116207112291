<template>
  <el-dialog title="Upload Voucher Image" :show-close="false" :visible.sync="dialogFormVisible">
    <el-form :model="form">
      <el-form-item label="Order Id" :label-width="formLabelWidth">
        {{ orderId }}
      </el-form-item>
      <el-form-item label="Voucher Image" :label-width="formLabelWidth">
        <el-upload
          drag
          :action="`${thisApi}/system/summary/otUploadImg`"
          :on-success="handleUploadSuccess"
          :on-error="handleUploadError"
        >
          <i class="el-icon-upload" />
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
        </el-upload>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">Cancel</el-button>
      <el-button type="primary" @click="handleUpdate">Confirm</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { otSubmit } from '../../../api/modifys/modify';
export default {
  props: {
    orderId: {
      type: [String ,Number],
      default: ''
    },
    dialogFormVisible: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      thisApi: process.env.VUE_APP_BASE_API,
      formLabelWidth: '120px',
      form: {
        voucherImageAddress: ''
      },
    }
  },
  methods: {
    handleUploadError () {
      this.$message({
        type: 'error',
        message:'upload fail'
      })
    },
    handleUploadSuccess(data) {
      if (data.msg) {
        this.$message({
          type: 'success',
          message:'upload success'
        })
        this.form.voucherImageAddress = data.msg
      } else {
        this.handleUploadError()
      }
    },
    handleUpdate () {
      if(this.form.voucherImageAddress) {
        otSubmit({
          orderId: this.orderId, 
          voucherImageAddress: this.form.voucherImageAddress
        }).then(res => {
          if(res.code === 200) {
            this.handleClose()
          }
        })
        
      } else {
        this.$message({
          type: 'error',
          message:'Please upload image'
        })
      }
    },
    handleClose (){
      this.$emit('close')
    }
  }
}
</script>